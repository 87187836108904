.page {
    font-family: verdana;
    min-height: 100vh;
    .display(flex);
    .flex-direction(column);

    .header {
        background: #0071BC;
        color: #FFFFFF;
        padding: 10px;

        .top {
            margin: 0 auto;
            width: 960px;
            font-size: 12px;
            line-height: 26px;

            .logo {
                float: left;
                width: 150px;

                h1 {
                    padding: 0;
                    margin: 0;
                    font-size: 16px;
                    font-weight: normal;
                }
            }

            a {
                color: #FFFFFF;
                text-align: center;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            .menu {
                float: left;

                a {
                    display: block;
                    float: left;
                    border-left: 1px solid #FFFFFF;
                    width: 120px;

                    &:last-child {
                        border-right: 1px solid #FFFFFF;
                    }
                }
            }

            .user {
                float: right;
                text-align: right;
            }
        }
    }

    .content {
        padding: 0 40px;
        .display(flex);
        .flex-direction(column);
        .flex(1 1 auto);

        .menu {
            border-right: 1px solid #666666;
            float: left;
            margin: 25px 20px 2px 20px;

            a {
                display: block;
                float: left;
                border-left: 1px solid #666666;
                width: 100px;
                text-align: center;
                font-size: 12px;
                line-height: 20px;
                text-decoration: none;
                color: #0071BC;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .add,
        .print,
        .export {
            float: right;

            a {
                display: block;
                width: 100px;
                color: #FFFFFF;
                height: 25px;
                background: #0071BC url("../images/button_login.png") repeat-x center;
                border: 0;
                .border-radius(3px);
                font-size: 12px;
                line-height: 25px;
                text-decoration: none;
                text-align: center;
            }

            &.disabled a {
                background-image: url("../images/button_login_disabled.png");
            }
        }

        .add {
            margin: 20px 20px 2px 2px;
        }

        .print,
        .export {
            margin: 20px 5px 2px 20px;
        }

        .main {
            background: #FFFFFF;
            padding: 20px;
            .display(flex);
            .flex-direction(column);
            .flex(1 1 auto);

            h2 {
                background: none repeat scroll 0 0 #E6E6E6;
                color: #0071BC;
                font-size: 18px;
                font-weight: normal;
                margin: 0;
                padding: 11px 14px;
            }

            h3 {
                clear: both;
                margin: 5px 0;
            }

            .form {
                background: none repeat scroll 0 0 #F9F9F9;
                .border-radius(10px);
                float: left;
                margin: 20px 0;
                padding: 30px;

                table {
                    font-size: 10px;

                    tr {
                        line-height: 25px;
                        vertical-align: top;
                    }

                    th {
                        padding: 0 5px;
                    }

                    td {
                        padding: 0 5px;
                    }
                }

                .button {
                    color: #FFFFFF;
                    height: 25px;
                    background: #0071BC url("../images/button_login.png") repeat-x center;
                    border: 0;
                    margin: 20px 3px 0 3px;
                    .border-radius(3px);
                    width: 100px;

                    &.first {
                        float: left;
                    }

                    &.disabled {
                        background-image: url("../images/button_login_disabled.png");
                    }
                }

                label {
                    font-weight: normal;
                }

                input, textarea {
                    width: 300px;
                    border: 1px solid #E6E6E6;
                }

                input[type=checkbox] {
                    width: 14px;
                    margin: 0;
                }

                .detail, .detail-nojs {
                    input, select {
                        margin-left: 5px;
                        width: 135px;
                    }
                }

                select[readonly] {
                    color: #CCCCCC !important;
                    pointer-events: none !important;
                }

                .note {
                    margin-left: 5px;
                    width: 135px;
                }
            }

            a {
                &.button {
                    display: inline-block;
                    font-size: 12px;
                    text-align: center;
                    text-decoration: none;
                }
            }

            .submenu {
                background-color: #EEEEEE;
                float: left;
                margin: 0px 20px 20px 0px;
                padding: 2px 0;

                a {
                    display: block;
                    float: left;
                    border-left: 1px solid #666666;
                    //width: 120px;
                    padding: 0 10px;
                    text-align: center;
                    font-size: 12px;
                    line-height: 20px;
                    text-decoration: none;
                    color: #0071BC;

                    &:hover {
                        text-decoration: underline;
                    }
                    &.selected {
                        font-weight: bold;
                        text-decoration: underline;
                    }
                    &:last-child {
                        border-right: 1px solid #666666;
                    }
                }
            }

            .grido {
                margin-bottom: 30px;
            }

            .iframe {
                .display(flex);
                .flex-direction(column);
                .flex(1 1 auto);

                iframe {
                    width: 100%;
                    border: none;
                    min-height: 400px;  // kdyby nefungoval flexbox
                    .flex(1 1 auto);
                }
            }

            table {
                &.sortable {
                    tbody {
                        tr {
                            cursor: move;
                        }
                    }
                }
            }

        }
    }
}

//Tisk
@media print {
    .page {
    }

    .page-landscape {
        width: 1035px;
    }
}
