.page-evidence-sluzby {
    .grido {
        .grid-header-datum {
            width: 6%;
        }

        .grid-header-dodavatel {
            width: 9%;
        }

        .grid-header-odberatel {
            width: 6%;
        }

        .grid-header-predmet_objednavky {
            width: 17%;
        }

        .grid-header-cislo_objednavky {
            width: 6%;
        }

        .grid-header-mnozstvi {
            width: 6%;
        }

        .grid-header-cena {
            width: 7%;
        }

        .grid-header-platba {
            width: 7%;
        }

        .grid-header-termin {
            width: 6%;
        }

        .grid-header-vystavil {
            width: 7%;
        }

        .grid-header-prebral {
            width: 7%;
        }

        .grid-header-prebral_datum {
            width: 5%;
        }

        .grid-header-faktura {
            width: 6%;
        }

        .actions {
            width: 5%;
        }
    }

    .polozky {
        td {
            input {
                width: 100% !important;
            }
        }
    }

    .detail {
        margin-left: -5px;
    }
}