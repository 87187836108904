/**
 * GRIDO
 */

.grido table {
    width: 100%;
    border-spacing: 0px;
    border-collapse: collapse;
    font-size: 11px;
}

.grido td, .grido th {
    border: 1px dotted #AAAAAA;
    padding: 3px;
    vertical-align: middle;
}

.grido .head {
    background: #E6E6E6;
}

.grido .head th {
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
}

.grido .head th a {
    color: #666666;
    text-decoration: none;
}

.grido .head th a:hover {
    text-decoration: underline;
}

.grido .head th.asc span {

}

.grido .head th.desc span {

}

.grido .filter {
    background: #f9f9f9;
}

.grido .filter th {
    text-align: center;
}

.grido .filter input {
    width: 96%;
    border: 1px solid #E6E6E6;
    height: 16px;
    margin: 3px 0;
}

.grido .filter .buttons {
    width: 52px;
}

.grido .filter .buttons .btn {
    width: 20px;
    height: 20px;
    float: left;
    background: #48AAF2 no-repeat center;
    border: 0;
    margin: 3px;
    font-size: 0px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
}

.grido .filter .buttons .search {
    background-image: url("../images/icon-search.png");
}

.grido .filter .buttons .reset {
    background-image: url("../images/icon-reset.png");
}

.grido .actions .btn {
    width: 20px;
    height: 20px;
    float: left;
    background: #48AAF2 no-repeat center;
    border: 0;
    margin: 3px;
    font-size: 0px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
}

.grido .actions .btn.disabled {
    background-color: #cccccc;
}

.grido .actions .grid-action-edit {
    background-image: url("../images/icon-edit.png");
}

.grido .actions .grid-action-delete {
    background-image: url("../images/icon-delete.png");
}

.grido .actions .grid-action-printPdf {
    background-image: url("../images/icon-print.png");
}

.grido tfoot td {
    text-align: right;
}

.grido .count select {
    margin: 3px;
}

.grido .count input {
    color: #FFFFFF;
    height: 25px;
    background: #0071BC url("../images/button_login.png") repeat-x center;
    border: 0;
    margin: 3px;
    border: 0;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    width: 150px;
}

.grido .count input.disabled {
    background: url("../images/button_login_disabled.png");
}


.grido {
    .grid-cell-order {
        text-align: center;
    }

    .grid-row-odd {
        background-color: #FFFFFF;
    }
    .grid-row-even {
        background-color: #EFEFEF;
    }
}
