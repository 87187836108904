.page-evidence-papir {
    .grido {

        .grid-header-datum {
            width: 6%;
        }

        .grid-header-gramaz {
            width: 5%;
        }

        .grid-header-typ_papiru {
            width: 6%;
        }

        .grid-header-format {
            width: 4%;
        }

        .grid-header-mnozstvi {
            width: 8%;
        }

        .grid-header-stroj {
            width: 5%;
        }

        .grid-header-rezat {
            width: 5%;
        }

        .grid-header-dodavatel {
            width: 6%;
        }

        .grid-header-cislo_objednavky {
            width: 6%;
        }

        .grid-header-zakazka {
            width: 16%;
        }

        .grid-header-konsignace {
            width: 4%;
        }

        .grid-header-skladnik {
            width: 5%;
        }

        .grid-header-skladnik_datum {
            width: 5%;
        }

        .grid-header-cena_celkem {
            width: 5%;
        }

        .grid-header-faktura {
            width: 6%;
        }

        // nepouzito
        .grid-header-pdf {
            width: 5%;
        }

        .actions {
            width: 8%;
        }
    }
}

// Tisk
@media print {
    .page-evidence-papir {
        table {

            width: 100%;
            border-spacing: 0;
            border-collapse: collapse;

            th {
                background: #000000;
                color: #FFFFFF;
                text-transform: uppercase;
                font-weight: normal;
                padding: 0 7px;
                border: 1px solid black;
                height: 38px;
                font-size: 18px;
            }

            td {
                border: 1px solid black;
                padding: 0 7px;
                text-align: center;
                height: 189px;
                font-size: 40px;

                &.aleft {
                    text-align: left;
                }
            }

            &.palety {
                margin: 93px 0 0 0;

                td {
                    height: 94px;
                }
            }
        }
    }
}