.page-evidence-tiskar {
    .content {
        .main {
            .form-edit {
                .polozky {
                    input {
                        width: 110px;
                    }
                }
                .button {
                    &.print {
                        background: #48AAF2 no-repeat center;
                        background-image: url("../images/icon-print.png");
                        width: 20px;
                        height: 20px;
                        float: left;
                        border: 0;
                        margin: 3px;
                        font-size: 0px;
                        border-radius: 3px;
                        -webkit-border-radius: 3px;
                    }
                }
            }
        }
    }
}

@media print {
    .page-evidence-tiskar {
        table {

            width: 100%;
            border-spacing: 0;
            border-collapse: collapse;

            th {
                background: #000000;
                color: #FFFFFF;
                text-transform: uppercase;
                font-weight: normal;
                padding: 0 7px;
                border: 1px solid black;
                height: 38px;
                font-size: 18px;
            }

            td {
                border: 1px solid black;
                padding: 0 7px;
                text-align: center;
                height: 160px;
                font-size: 40px;

                &.aleft {
                    text-align: left;
                }
            }
        }

        .footer {
            margin-top: 20px;
            font-size: 26px;
        }
    }
}
