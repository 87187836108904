.page-evidence-termin {
    .polozky {
        td {
            input {
                width: 100% !important;
            }
        }
    }

    .detail {
        margin-left: -5px;
    }
}