body.page-sign {
    background: #FFFFFF;

    .login {
        margin: 60px auto 0;
        background: none repeat scroll 0 0 #F5F5F5;
        height: 335px;
        padding: 1px;
        width: 500px;
        line-height: 1.25;
        color: #000000;
        .border-radius(15px);

        h1 {
            font-size: 29px;
            font-weight: normal;
            margin: 32px 0 0 66px;
        }

        p {
            color: #808080;
            font-size: 12px;
            margin: 10px 0 0 66px;
        }

        table {
            margin: 35px 0 0 70px;

            tr {
                th, td {
                    line-height: 25px;
                    padding-bottom: 20px;
                    text-align: left;
                }

                label {
                    display: block;
                    float: left;
                    font-size: 14px;
                    margin-right: 15px;
                    text-align: right;
                    font-weight: normal;
                    width: 52px;
                }

                input {
                    border: 1px solid #E6E6E6;
                    height: 23px;
                    width: 230px;
                }

                .button {
                    background: url("../images/button_login.png") repeat scroll 0 0 transparent;
                    border: 0;
                    color: #FFFFFF;
                    font-size: 15px;
                    height: 28px;
                    margin: 35px 0 0 165px;
                    width: 125px;
                }

                .button.disabled {
                    background-image: url("../images/button_login_disabled.png");
                }
            }
        }
    }

    .login-note {
        margin: 20px auto;
        width: 365px;
        font-size: 13px;
        line-height: 23px;
        color: #808080;
    }
}