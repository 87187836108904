.page-komunikace-chat {
    table {
        tr {
            td {
                padding: 10px;
                border: 1px solid #ccc;
                vertical-align: top;
            }
        }
    }

    .chat-container {
        width: 100%;

        .chat-messages {
            overflow-y: auto;
            height: calc(~"100vh - 320px");

            .message {
                .message-content {
                    padding: 5px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    margin-bottom: 5px;

                    .author {
                        font-size: 14px;
                    }

                    .time {
                        font-size: 10px;
                        padding-left: 10px;
                        color: #ccc;
                    }
                }
            }
        }

        .chat-users {
            overflow-y: auto;
        }

        .submit-form {
            .chat-text {
                width: 100%;
                resize: none;
            }
            .button {
                float: right;
                color: #FFF;
                height: 25px;
                background: url(../images/button_login.png) center repeat-x #0071BC;
                border: 0;
                margin: 20px 3px 0;
                border-radius: 3px;
                background-clip: padding-box;
                width: 100px;
            }
        }
    }
}