.page-evidence-zakazka {
    .grido {

        .grid-header-colapse {
            width: 2%;
        }

        .grid-header-cislo {
            width: 5%;
        }

        .grid-header-firma {
            width: 7%;
        }

        .grid-header-zakazka {
            width: 25%;
        }

        .grid-header-pocet_kusu {
            width: 5%;
        }

        .grid-header-prijeti {
            width: 7%;
        }

        .grid-header-termin {
            width: 7%;
        }

        .grid-header-datum_obj {
            width: 7%;
        }

        .grid-header-datum_dodani {
            width: 7%;
        }

        .grid-header-vyrizuje {
            width: 6%;
        }

        .grid-header-predani_dat_datum {
            width: 6%;
        }

        .grid-header-aktivni {
            width: 5%;
        }

        .grid-header-tiskova_data {
            width: 4%;
        }

        .grid-header-ctp {
            width: 4%;
        }

        .grid-header-papir {
            width: 4%;
        }

        .grid-header-vytisteno {
            width: 4%;
        }

        .grid-header-plan_expedice {
            width: 4%;
        }

        .grid-header-vyfakturovano {
            width: 4%;
        }

        .grid-header-faktura {
            width: 6%;
        }

        .actions {
            width: 5%;
        }

        .collapsed-row {
            color: #AAAAAA;
        }
    }
}
