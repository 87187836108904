.page-evidence-plan {
    .grido {

        .grid-header-cislo {
            width: 5%;
        }

        .grid-header-firma_zakazka {
            width: 20%;
        }

        .grid-header-firma {
            width: 10%;
        }

        .grid-header-zakazka {
            width: 15%;
        }

        .grid-header-prijeti {
            width: 5%;
        }

        .grid-header-termin {
            width: 5%;
        }

        .grid-header-stroj {
            width: 5%;
        }

        .grid-header-hodiny {
            width: 5%;
        }

        .grid-header-papir {
            width: 22%;
        }

        .grid-header-predani_dat_datum {
            width: 5%;
        }

        .grid-header-tiskova_data_datum {
            width: 5%;
        }

        .grid-header-osvit_datum {
            width: 5%;
        }

        .grid-header-tisk {
            width: 5%;
        }

        .grid-header-stav {
            width: 5%;
        }

        .actions {
            width: 6%;
        }
    }
}
