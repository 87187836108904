body {
    font-size: 15px;
    line-height: 1.6;
    color: #666666;
    background: #EEEEEE;
    padding: 0;
    margin: 0;

    .clear {
        clear: both;
    }

    .ui-widget {
        font-size: 12px;
    }

}

// Tisk
@media print {
    body {
        font: 12pt/1.4 "Trebuchet MS", "Geneva CE", lucida, sans-serif;
        color: black;
        background: none;
        width: 100%;

        a img {
            border: none;
        }

        #ajax-spinner {
            display: none;
        }

        .aleft {
            text-align: left !important;
        }

    }
}