
.icon {

  width: 14px;
  height: 14px;
  margin: 6px 0;
  cursor: pointer;
  display: inline-block;
  opacity: 0.60;

  &:hover {
    opacity: 1;
  }

  &.icon-open {
    background-image: url("../images/icon-open.png");
  }

  &.icon-close {
    background-image: url("../images/icon-close.png");
  }

  &.icon-loading {
    background-image: url("../images/spinner.gif");
  }

  &.icon-up {
    background-image: url("../images/up.png");
  }

  &.icon-down {
    background-image: url("../images/down.png");
  }

}
