.page-evidence-prijemka {
    .grido {
        .actions {
            width: 8%;
        }
    }

    .page .content .main .form {
        textarea[name=polozky] {
            width: 800px;
            font-size: 12px;
        }
    }
}

// Tisk
@media print {
    .page-evidence-papir {
        table {

            width: 100%;
            border-spacing: 0;
            border-collapse: collapse;

            th {
                background: #000000;
                color: #FFFFFF;
                text-transform: uppercase;
                font-weight: normal;
                padding: 0 7px;
                border: 1px solid black;
                height: 38px;
                font-size: 18px;
            }

            td {
                border: 1px solid black;
                padding: 0 7px;
                text-align: center;
                height: 189px;
                font-size: 40px;

                &.aleft {
                    text-align: left;
                }
            }

            &.palety {
                margin: 93px 0 0 0;

                td {
                    height: 94px;
                }
            }
        }
    }
}
