.page-evidence-technologie {
    .form {
        td {
            padding: 0 2px !important;

            &.sumarizace {
                line-height: 16px;
                padding-left: 120px !important;

                .poznamka {
                    color: #AAAAAA;
                }
            }

            &.technologie {
                .pocet, .norma, .hodiny, .suma {
                    width: 100% !important;
                    text-align: center;
                }
                .hodiny, .suma {
                    text-align: right;
                }
            }

            &.ukony {
                padding-left: 35px !important;

                input {
                    width: 100% !important;
                    text-align: center;
                    max-width: 96px;

                    &.hodiny, &.suma {
                        text-align: right;
                    }
                }
            }
        }
    }
}
